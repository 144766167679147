import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  REFERRAL_FETCH,
  REFERRAL_FETCH_ONE,
  REFERRAL_LAST_FETCH,
  REFERRAL_UPDATE,
  REFERRAL_CLEAR
} from './types';

export const getReferrals = (params = [], limit = 100, options = {
  attrType: 'custom',
  columns: [
    'refCode',
    'createByApp',
    'createByAppName',
    'createdAt',
    'careGiver',
    'careGiverFirstName',
    'careGiverLastName',
    'updatedAt',
    'patCode',
    'patient',
    'patientFirstName',
    'patientLastName',
    'patientAge',
    'mapImageDoctor',
    'mapImagePatient',
    'patientBirthdate',
    'patientGender',
    'information',
    'pendingInformation',
    'drugAllergyHistory',
    'attachments',
    'patientMobile',
    'patientEmail',
    'patientNation',
    'patientAllowToShowHistory',
    'primaryHn',
    'doctor',
    'status',
    'medOrder',
    'acceptMedOrder',
    'confirmMedOrder',
    'confirmMedOrderDate',
    'type',
    'subType',
    'doctorNotesChiefCompliant',
    'doctorNotesConsultantionSummary',
    'doctorNotesProvisionalDiagnosis',
    'doctorNotesDoctorRecommendations',
    'icd10',
    'rejectAs',
    'cancelReason',
    'caseType',
    'isDemo',
    'doctorPrice',
    'doctorPrefix',
    'payDoctor',
    'payDoctorType',
    'consultTime',
    'statusLogs',
    'payment',
    'volunteerOnly',
    'couponCode',
    'couponDescription',
    'useInNewDesign',
    'useWeb',
    'shortLinkForComplete',
    'currentFcmToken',
    'currentApnToken',
    'userRecivedCall',
    'useInNewMiniDesign',
    'patientWaitInRoom',
    'doctorWaitInRoom',
    'doctorRecivedCall',
    'medRef',
    'medOrderStatus',
    'deliveryAddress',
    'deliveryAddressMain',
    'followup',
    'type',
    'doctorCalling',
    'newDocumentMedOrderPdfUrl',
    'newDocumentPaymentPdfUrl',
    'newDocumentDoctorVeifyPdfUrl',
    'newDocumentInsurePdfUrl',
    'guest',
    'guestDetail',
    'linkAuthPatient',
    'linkAuthDoctor',
    'lineUserId',
    'lineOwnerCode',
    'medPaymentStatus',
    'destinationAddress',
    'doctorNotesDrugRecommendations',
    'shipping',
    'completedAt',
    'createdFirstName',
    'createdLastName',
    'createdMobile',
    'trackingChannel',
    'trackingNumber',
    'medicineFollow',
    'adminSetBooking',
    'createdHosName',
    'webDomainForUse',
    'patientIsVerify',
    'patientSource',
    'captureScreen',
    'hospitalName',
    'newDocumentMedicalCertificateUrl',
    'isShowMedicalCertificate',
    'dazRequestBookingRefCode',
    'refCompanyCode',
    'useInsurance',
    'insuranceCode',
    'companyName',
    'kioskId'
  ]
}, type = REFERRAL_FETCH, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const companyCode = localStorage.getItem('company-code');
  if (companyCode) {
    params.push(`refCompanyCode=${companyCode}`);
  }
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `referrals?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: REFERRAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_FETCH: true } });
  await axios.get(url).then(response => {
    if (type === REFERRAL_LAST_FETCH) {
      const lastData = (response.data && response.data.data) || [];
      dispatch({ type, playload: { lastData, status: 'success' } });
    } else {
      const data = (response.data && response.data.data) || [];
      dispatch({
        type,
        playload: {
          data,
          status: 'success',
          isAddMore,
          nextKey: response?.data?.nextKey
        }
      });
    }
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({
      type,
      playload: {
        data: [],
        status: 'success',
        isAddMore,
        nextKey: ''
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getReferralByCode = refCode => async dispatch => {
  const url = `referrals?code=${refCode}`;

  dispatch({ type: REFERRAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_FETCH_ONE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.refCode === refCode);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: REFERRAL_FETCH_ONE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_FETCH_ONE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_FETCH_ONE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const referralUpdate = (refCode, dataSet, isShowLoading = true) => async dispatch => {
  const url = `referrals/${refCode}`;

  dispatch({ type: REFERRAL_CLEAR });
  dispatch({ type: IS_LOADING, status: isShowLoading, names: { REFERRAL_UPDATE: isShowLoading } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: REFERRAL_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: REFERRAL_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const referralCreate = dataSet => async dispatch => {
  const url = 'referrals';

  dispatch({ type: REFERRAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { REFERRAL_UPDATE: true } });
  await axios.post(url, dataSet).then(response => {
    const resData = response?.data;
    const detail = ((resData && resData?.data) || {});
    let message = null;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
      message = resData?.message || resData?.error;
    }
    dispatch({ type: REFERRAL_UPDATE, playload: { detail, status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: REFERRAL_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const referralCancel = (refCode, dataSet, isShowLoading = true) => async dispatch => {
  const url = `referrals/cancel/${refCode}`;

  dispatch({ type: REFERRAL_CLEAR });
  dispatch({ type: IS_LOADING, status: isShowLoading, names: { REFERRAL_UPDATE: isShowLoading } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: REFERRAL_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: REFERRAL_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const referralClear = () => dispatch => {
  dispatch({ type: REFERRAL_CLEAR });
};

export const genDocuments = async refCode => {
  const url = '/reports/pdf/consult/generate';
  return new Promise(reslove => {
    axios.post(url, { refCode }).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};

export const referUpdate = async (refCode, dataSet) => {
  const url = `referrals/${refCode}`;
  return new Promise(reslove => {
    axios.put(url, dataSet).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};

export const updateWallet = async (type, dataSet) => {
  const url = `wallet/${type}`;
  return new Promise(reslove => {
    axios.post(url, dataSet).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};
